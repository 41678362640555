// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* flex-direction: row | row-reverse | column | column-reverse; */
.container {
  display: flex;
  flex-direction: column;
  height: 88vh;
  flex-wrap: wrap;
  width: 20%;
  padding: 15px;
}

.menu-icons {
  height: 70px;
  width: 70px;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/desktop-menu/desktop-menu.scss"],"names":[],"mappings":"AAAA,iEAAA;AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;EACA,aAAA;AAAF;;AAGA;EACE,YAAA;EACA,WAAA;EACA,mBAAA;AAAF","sourcesContent":["/* flex-direction: row | row-reverse | column | column-reverse; */\n\n.container {\n  display: flex;\n  flex-direction: column;\n  height: 88vh;\n  flex-wrap: wrap;\n  width: 20%;\n  padding: 15px;\n}\n\n.menu-icons {\n  height: 70px;\n  width: 70px;\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
