import "./desktop-menu.scss";
import myComputer from "../../assets/icons/My Computer.png";
import recycleBin from "../../assets/icons/Recycle Bin.png";
import freeCell from "../../assets/icons/game_freecell-1.png";
import hearts from "../../assets/icons/game_hearts.png";
import mine from "../../assets/icons/game_mine_2-0.png";
import solitaire from "../../assets/icons/game_solitaire-0.png";
import spider from "../../assets/icons/game_spider-0.png";
import ourTools from "../../assets/icons/Our Tools.png";
import ourSerices from "../../assets/icons/Our Services.png";
import setting from "../../assets/icons/Settings.png";
import projects from "../../assets/icons/Projects.png";
import ie from "../../assets/icons/Internet Explorer.png";
import workTogether from "../../assets/icons/Lets Work Together.png";
import {
  WorkTogether,
  OurGallary,
  OurServices,
  OurTools,
  OurValues,
  OurProject,
} from "../pages";

const DesktopMenu = (props) => {
  const menus = [
    {
      id: "MY_COMPUTER",
      title: "My Computer",
      iconUrl: myComputer,
      component: <></>,
    },
    {
      id: "RECYCLE_BIN",
      title: "Recycle Bin",
      iconUrl: recycleBin,
      component: <>Recycle Bin</>,
    },
    {
      id: "FREECELL",
      title: "Freecell",
      iconUrl: freeCell,
      component: <>Freecell</>,
    },
    {
      id: "HEARTS",
      title: "Hearts",
      iconUrl: hearts,
      component: <>Hearts</>,
    },
    {
      id: "MINESWEEPER",
      title: "Minesweeper",
      iconUrl: mine,
      component: <>Minesweeper</>,
    },
    {
      id: "SOLITAIRE",
      title: "Solitaire",
      iconUrl: solitaire,
      component: <>Solitaire</>,
    },
    { id: "SPIDER", title: "Spider", iconUrl: spider, component: <>Spider</> },
    {
      id: "OUR_TOOLS",
      title: "Our Tools",
      width: "450px",
      left: "35%",
      iconUrl: ourTools,
      component: <OurTools></OurTools>,
    },
    {
      id: "OUR_SERVICES",
      title: "Our Services",
      iconUrl: ourSerices,
      component: <OurServices></OurServices>,
    },
    {
      id: "SETTINGS",
      title: "our values",
      iconUrl: setting,
      component: <OurValues></OurValues>,
    },
    // {
    //   id: "SETTINGS",
    //   title: "Projects",
    //   iconUrl: projects,
    //   component: <OurProject></OurProject>
    // },

    {
      id: "IE",
      title: "Events Gallary",
      iconUrl: ie,
      component: <OurGallary></OurGallary>,
    },
    {
      id: "WORK_TOGETER",
      title: "Let's Work Together",
      iconUrl: workTogether,
      component: <WorkTogether></WorkTogether>,
    },
  ];
  return (
    <div className="container">
      {menus.map((item) => {
        return (
          <div className="menu-icons" onClick={() => props.onDoubleClick(item)}>
            <div class="icon-wrapper">
              <img
                class="icon"
                draggable="false"
                src={item.iconUrl}
                width="32px"
                height="32px"
                alt=""
              />
            </div>
            <div class="title">{item.title}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DesktopMenu;
