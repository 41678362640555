import React, { useState } from "react";
import "./our-gallary.scss";

const EventDetails = (props) => {
  console.log("EventDetails", props.data);
  const [images, setImages] = useState(props.data.images);

  // const [ minimize, setMinimize ] = useState(false);

  const [resize, setResize] = useState(true);
  const [open, setOpen] = useState(true);

  const minimizeClick = () => {
    console.log("minimizeClick");
  };

  return (
    <>
      {open && (
        <div
          class="window"
          style={
            resize
              ? {
                  width: "80%",
                  top: props.data.index,
                  transform: "translate(50% 50%)",
                  background: "#fff",
                }
              : { width: "100%", height: "100%", background: "#fff" }
          }
        >
          <div class="title-bar">
            <div class="title-bar-text">{props.data.shortTitle}</div>
            <div class="title-bar-controls">
              <button
                aria-label="Minimize"
                onClick={() => minimizeClick()}
              ></button>
              <button
                aria-label="Maximize"
                onClick={() => setResize(!resize)}
              ></button>
              <button
                aria-label="Close"
                onClick={() => {
                  props.setView(false);
                }}
              ></button>
            </div>
          </div>
          <div class="window-body">
            <div class="status-bar">
              <div class="status-bar-field">
                <p className="event-title">{props.data.title}</p>
                <p className="event-desc">{props.data.date}</p>
                <p className="event-desc">{props.data.desc}</p>
                <img src={images[0]} height="300" className="img-full-width" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventDetails;
