import "./lets-work-together.scss";

const WorkTogether = () => {
  return (
    <div className="work-together">
      {/* <form> */}
      <h3 className="form-title">
        GOT A PROJECT ? <br />
        LETS WORK TOGETHER{" "}
      </h3>
      <div className="email-style">software@papayaasia.com</div>
      {/* <div style={{ display: "flex" }}>
          <input
            className="half-width height-input"
            type="text"
            placeholder="First Name"
          ></input>
          <input
            className="half-width height-input"
            type="text"
            placeholder="Last Name"
          ></input>
        </div>
        <input
          className="fill-width height-input"
          type="email"
          placeholder="Email"
        ></input>
        <input
          className="fill-width text-area-height"
          type="textarea"
          placeholder="Message"
        ></input>
        <button className="button-style"> Contact our team </button>
      </form> */}
    </div>
  );
};

export default WorkTogether;
