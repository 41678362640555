// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our-values-container {
  display: flex;
  flex-direction: row;
  /* height: auto; */
  flex-wrap: wrap;
  /* width: 100%; */
  padding: 15px;
  background-color: #f2f2f2;
  height: 600px;
  overflow: scroll;
  overflow: auto;
  justify-content: space-between;
}
.our-values-container .icon-with-name {
  background-color: #fff;
  width: 25%;
  height: auto;
  /* padding: 20px; */
  /* margin: 0px; */
  padding: 20px;
  border-radius: 12px;
}
@media (max-width: 850px) {
  .our-values-container .icon-with-name {
    width: 100%;
  }
}
.our-values-container .services-img {
  height: 150px;
  width: 150px;
}
.our-values-container .title-font {
  color: black;
  font-family: "px_sans";
  font-size: 1rem;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  font-weight: bold;
}
.our-values-container .desc {
  font-family: "HarmonyMedium";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/our-values/our-values.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;EACA,cAAA;EACA,8BAAA;AACF;AACE;EACE,sBAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAEE;EACE;IACE,WAAA;EAAJ;AACF;AAGE;EACE,aAAA;EACA,YAAA;AADJ;AAGE;EACE,YAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;AADJ;AAGE;EACE,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AADJ","sourcesContent":[".our-values-container {\n  display: flex;\n  flex-direction: row;\n  /* height: auto; */\n  flex-wrap: wrap;\n  /* width: 100%; */\n  padding: 15px;\n  background-color: #f2f2f2;\n  height: 600px;\n  overflow: scroll;\n  overflow: auto;\n  justify-content: space-between;\n\n  .icon-with-name {\n    background-color: #fff;\n    width: 25%;\n    height: auto;\n    /* padding: 20px; */\n    /* margin: 0px; */\n    padding: 20px;\n    border-radius: 12px\n  }\n\n  @media (max-width: 850px){\n    .icon-with-name {\n      width: 100%;\n    }\n  }\n\n  .services-img {\n    height: 150px;\n    width: 150px;\n  }\n  .title-font {\n    color: black;\n    font-family: 'px_sans';\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 28px;\n    letter-spacing: 0.04em;\n    text-align: center;\n    font-weight: bold;\n  }\n  .desc {\n    font-family: 'HarmonyMedium';\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: 0em;\n    text-align: center;\n    color: gray;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
