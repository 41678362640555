// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.work-together {
  background-color: #1a1a1a;
  padding: 100px;
  padding-top: 43px;
}
.work-together .form-title {
  font-family: "px_sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #ffb700;
}
.work-together .fill-width {
  width: 97.5%;
  margin: 10px;
}
.work-together .half-width {
  width: 50%;
  margin: 10px;
}
.work-together .height-input {
  height: 56px;
  padding-left: 15px;
}
.work-together .text-area-height {
  height: 110px;
  padding-left: 15px;
}
.work-together .button-style {
  float: left;
  font-family: "HarmonyMedium";
  font-size: 16px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #ffb700;
  color: #1a1a1a;
  height: 38px;
}

.email-style {
  height: 40px;
  width: 60%;
  background: #fff;
  margin: 0 auto;
  font-size: 30px;
  padding: 20px;
  font-family: "HarmonyMedium";
}`, "",{"version":3,"sources":["webpack://./src/components/pages/lets-work-together/lets-work-together.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;EACA,iBAAA;AACF;AAAE;EACE,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,cAAA;AAEJ;AACE;EACE,YAAA;EACA,YAAA;AACJ;AAEE;EACE,UAAA;EACA,YAAA;AAAJ;AAEE;EACE,YAAA;EACA,kBAAA;AAAJ;AAEE;EACE,aAAA;EACA,kBAAA;AAAJ;AAGE;EACE,WAAA;EACA,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,YAAA;AADJ;;AAKA;EACE,YAAA;EACA,UAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,aAAA;EACA,4BAAA;AAFF","sourcesContent":[".work-together {\n  background-color: #1a1a1a;\n  padding: 100px;\n  padding-top: 43px;\n  .form-title {\n    font-family: 'px_sans';\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 28px;\n    letter-spacing: 0.04em;\n    text-align: center;\n    color: #ffb700;\n  }\n\n  .fill-width {\n    width: 97.5%;\n    margin: 10px;\n  }\n\n  .half-width {\n    width: 50%;\n    margin: 10px;\n  }\n  .height-input {\n    height: 56px;\n    padding-left: 15px;\n  }\n  .text-area-height {\n    height: 110px;\n    padding-left: 15px;\n  }\n\n  .button-style {\n    float: left;\n    font-family: 'HarmonyMedium';\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 12px;\n    letter-spacing: 0em;\n    text-align: center;\n    background-color: #ffb700;\n    color: #1a1a1a;\n    height: 38px;\n  }\n}\n\n.email-style {\n  height: 40px;\n  width: 60%;\n  background: #fff;\n  margin: 0 auto;\n  font-size: 30px;\n  padding: 20px;\n  font-family: 'HarmonyMedium';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
