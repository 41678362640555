// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our-tools-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  background-color: #fff;
  justify-content: space-between;
}

.icon-with-name {
  margin-bottom: 30px;
  height: 70px;
  width: 70px;
  padding: 10px;
}

.icon-wrapper {
  padding: 10px;
}
.icon-wrapper .tools-icons {
  width: 50px;
  height: 50px;
}

/* .title {
  color: black;
} */`, "",{"version":3,"sources":["webpack://./src/components/pages/our-tools/our-tools.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EAEA,eAAA;EAEA,aAAA;EACA,sBAAA;EACA,8BAAA;AADF;;AAOA;EACI,mBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;AAJJ;;AAME;EACE,aAAA;AAHJ;AAMI;EACC,WAAA;EACA,YAAA;AAJL;;AAQE;;GAAA","sourcesContent":[".our-tools-container {\n  display: flex;\n  flex-direction: row;\n  // height: auto;\n  flex-wrap: wrap;\n  // width: 100%;\n  padding: 15px;\n  background-color: #fff;\n  justify-content: space-between;\n  \n}\n// .window{\n//   width: 450px !important;\n// }\n.icon-with-name {\n    margin-bottom: 30px;\n    height: 70px;\n    width: 70px;\n    padding: 10px;\n  }\n  .icon-wrapper{\n    padding: 10px;\n    // width: 70px;\n    // height: 70px;\n    .tools-icons{\n     width: 50px;\n     height: 50px\n    }\n  }\n\n  /* .title {\n    color: black;\n  } */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
