import "./our-values.scss";
import community from "../../../assets/icons/ppy_community.svg";
import easeofuser from "../../../assets/icons/ppy_consensus.svg";
import funtionality from "../../../assets/icons/ppy_constructive.svg";

const OurValues = () => {
  const menus = [
    {
      title: "COMMUNITY",
      subtitle: "(COMMUNAL)",
      iconUrl: community,
      desc: "We are devoted to the notion of being community driven as we are part of a community of users too. Bonds are built, ideas are exchanged and this is one of our drives. We are “from the Community”.",
    },
    {
      title: "EASE OF USE",
      subtitle: "(CONSENSUS)",
      iconUrl: easeofuser,
      desc: "We are seeing emerging tech trends moving into adoption. For many, this change can be complex hence, we design to create with the user’s journey in mind. We are “for the Community”.",
    },
    {
      title: "FUNCTIONALITY",
      subtitle: "(CONSTRUCTIVE)",
      iconUrl: funtionality,
      desc: "No one business is the same. Neither are its users. Defining the requirements to be implemented by a software solution requires understanding what your business and users need. We strive to fulfil this as we are “by the Community”.",
    },
  ];
  return (
    <div className="our-values-container">
      {menus.map((item) => {
        return (
          <div className="icon-with-name">
            <p className="title-font">{item.title}</p>
            <p className="title-font" style={{ color: "#ffc522" }}>
              {item.subtitle}
            </p>
            <div className="icon-wrapper">
              <img
                class="services-img"
                draggable="false"
                src={item.iconUrl}
                alt=""
              />
            </div>

            <p class="desc">{item.desc}</p>
          </div>
        );
      })}
    </div>
  );
};

export default OurValues;
