import { useState } from "react";
import "./our-gallary.scss";
import img1 from "../../../assets/images/Sponsored Events/MCS 2021/MCS 15 December 2021 01.jpg";
import img2 from "../../../assets/images/Sponsored Events/MCS 2021/MCS 15 December 2021 02.jpg";
import img3 from "../../../assets/images/Sponsored Events/MCS 2021/MCS 15 December 2021 03.jpg";
import img4 from "../../../assets/images/Sponsored Events/MCS 2022/MCS 21-22 May 2022 01.png";
import img5 from "../../../assets/images/Sponsored Events/MCS 2022/MCS 21-22 May 2022 02.png";
import img6 from "../../../assets/images/Sponsored Events/MCS 2023/MCS 6 May 2023 01.png";
import img7 from "../../../assets/images/Sponsored Events/MCS 2023/MCS 6 May 2023 02.png";
import EventDetails from "./image-gallary";

const OurGallary = () => {
  const MCS2021 = {
    shortTitle: "MCS 2021",
    title: "Malaysia Championship Series 2021 (Round 3-4 Race 2)",
    date: "15 December 2021",
    desc: "Papaya Asia first sponsored driver Danny Chin’s participation who drove Car 99 in the Malaysia Championship Series (MCS). This event is a national racing series promoted and organised by Sepang International Circuit (SIC) as an initiative to promote and develop 4-wheel racing sports in Malaysia.",
    images: [img1, img2, img3],
  };
  const MCS2022 = {
    shortTitle: "MCS 2022",
    title: "Malaysia Championship Series (Round 1 Race 1)",
    date: "21-22 May 2022",
    desc: "This year, Papaya Asia sponsored Car 22, driven by Roger Chew / Gnow Chui Luen. The Malaysia Championship Series (MCS) is a national racing series promoted and organised by Sepang International Circuit (SIC) as an initiative to promote and develop 4-wheel racing sports in Malaysia",
    images: [img4, img5],
  };
  const MCS2023 = {
    shortTitle: "MCS 2023",
    title: "Malaysia Championship Series (Round 1 Race 1)",
    date: "6 May 2023",
    desc: "Papaya Asia sponsored a car participating in the Malaysia Championship Series (MCS), a national racing series promoted and organised by Sepang International Circuit (SIC) as an initiative to promote and develop 4-wheel racing sports in Malaysia. Car 99, a Suzuki Swift, was driven by driver Danny Chin.",
    images: [img6, img7],
  };
  const all = {
    shortTitle: "All",
    images: [...MCS2021.images, ...MCS2022.images, ...MCS2022.images],
  };

  const data = [all, MCS2021, MCS2022, MCS2023];
  const [current, setCurrent] = useState(all);
  const [view, setView] = useState(false);

  return (
    <>
      {view && <EventDetails setView={setView} data={current}></EventDetails>}
      <div className="gallary">
        <div className="button-container">
          {data.map((item) => {
            return (
              <button
                class="start-button toggle"
                title="Click here to begin."
                onClick={(e) => {
                  console.log(item);
                  setCurrent(item);
                }}
              >
                <b>{item.shortTitle}</b>
              </button>
            );
          })}
        </div>
        <div className="image-gallary">
          {current.images.map((item) => (
            <img
              className="img-class"
              height="100"
              width="100"
              src={item}
              alt=""
              onClick={() => setView(true)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default OurGallary;
