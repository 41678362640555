import "./our-tools.scss";
import Android from "../../../assets/icons/Android robot.svg";
import IOS from "../../../assets/icons/iOS wordmark.svg";
import Ubuntu from "../../../assets/icons/ubuntu_brandmark.svg";
import HTML5 from "../../../assets/icons/HTML5 Logo.svg";
import CSS3 from "../../../assets/icons/CSS3_logo_and_wordmark.svg";
import php from "../../../assets/icons/php2.png";
import MongoDB from "../../../assets/icons/MongoDB_brandmark.svg";
import MySQL from "../../../assets/icons/logo-mysql.svg";
import aws from "../../../assets/icons/Amazon_Web_Services_Logo.svg";
import Backbone from "../../../assets/icons/bacbone.png";
import node from "../../../assets/icons/nodejs-new-pantone-black.svg";
import rr from "../../../assets/icons/React brandmark.svg";
import Flutter from "../../../assets/icons/flutter brandmark.svg";
import GoogleCloud from "../../../assets/icons/google_cloud brandmark only.svg";
import Vue from "../../../assets/icons/Vue brandmark.svg";
import ReactNative from "../../../assets/icons/React brandmark.svg";
import Python from "../../../assets/icons/python brandmark.svg";

const OurTools = () => {
  const menus = [
    { title: "Android", iconUrl: Android },
    { title: "iOS", iconUrl: IOS },
    { title: "Ubuntu", iconUrl: Ubuntu },
    { title: "HTML 5", iconUrl: HTML5 },
    { title: "CSS 3", iconUrl: CSS3 },
    { title: "php", iconUrl: php },
    { title: "MongoDB", iconUrl: MongoDB },
    { title: "MySQL", iconUrl: MySQL },
    { title: "aws", iconUrl: aws },
    { title: "Backbone.js", iconUrl: Backbone },
    { title: "node.js", iconUrl: node },
    { title: "React", iconUrl: rr },
    { title: "Flutter", iconUrl: Flutter },
    { title: "Google Cloud", iconUrl: GoogleCloud },
    { title: "Vue.js", iconUrl: Vue },
    { title: "Python", iconUrl: Python },
    { title: "Reat Native", iconUrl: ReactNative },
  ];
  return (
    <div className="our-tools-container">
      {menus.map((item) => {
        return (
          <div className="icon-with-name">
            <div class="icon-wrapper">
              <img
                class="tools-icons"
                draggable="false"
                src={item.iconUrl}
                alt=""
              />
            </div>
            <div class="title" style={{ color: "#000", fontSize: 14 }}>
              {item.title}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OurTools;
