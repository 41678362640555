// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our-services-container {
  display: flex;
  flex-direction: row;
  /* height: auto; */
  flex-wrap: wrap;
  /* width: 100%; */
  /* padding: 15px; */
  background-color: #f2f2f2;
  height: 600px;
  overflow: scroll;
  overflow: auto;
  justify-content: center;
}
.our-services-container .icon-with-name {
  background-color: #fff;
  width: 40%;
  height: auto;
  /* padding: 20px; */
  margin: 10px;
  padding: 20px;
  border-radius: 12px;
}
@media (max-width: 650px) {
  .our-services-container .icon-with-name {
    width: 100%;
  }
}
.our-services-container .services-img {
  height: 150px;
  width: 150px;
}
.our-services-container .title {
  color: black;
  font-family: "px_sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: center;
}
.our-services-container .desc {
  font-family: "HarmonyMedium";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/our-services/our-services.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;EACA,cAAA;EACA,uBAAA;AACF;AACE;EACE,sBAAA;EACA,UAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACE;EACE;IACE,WAAA;EACJ;AACF;AAEE;EACE,aAAA;EACA,YAAA;AAAJ;AAEE;EACE,YAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;AADJ","sourcesContent":[".our-services-container {\n  display: flex;\n  flex-direction: row;\n  /* height: auto; */\n  flex-wrap: wrap;\n  /* width: 100%; */\n  /* padding: 15px; */\n  background-color: #f2f2f2;\n  height: 600px;\n  overflow: scroll;\n  overflow: auto;\n  justify-content: center;\n  \n  .icon-with-name {\n    background-color: #fff;\n    width: 40%;\n    height: auto;\n    /* padding: 20px; */\n    margin: 10px;\n    padding: 20px;\n    border-radius: 12px\n  }\n  @media (max-width: 650px){\n    .icon-with-name {\n      width: 100%;\n    }\n  }\n\n  .services-img {\n    height: 150px;\n    width: 150px;\n  }\n  .title {\n    color: black;\n    font-family: 'px_sans';\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 28px;\n    letter-spacing: 0.04em;\n    text-align: center;\n    \n  }\n  .desc {\n    font-family: 'HarmonyMedium';\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: 0em;\n    text-align: center;\n    color: gray ;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
