import task from "../../assets/icons/task-scheduler-16x16.png";
import start from "../../assets/icons/windows-3.png";

const Taskbar = (props) => {
  const { data } = props;
  var date = new Date();

  const formatAMPM = (date = new Date()) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  return (
    <div class="taskbar" style={{ zIndex: 5000 }}>
      <button class="start-button toggle" title="Click here to begin.">
        <img src={start} style={{ paddingRight: 5 }} alt="" />
        <b>Start</b>
      </button>
      <div class="taskbar-divider"></div>
      <div class="tasks">
        {/* {data &&
          data.map((item) => {
            return (
              <button class="start-button toggle" title="Click here to begin.">
                <b>{item.title}</b>
              </button>
            );
          })} */}
      </div>
      <div class="taskbar-divider"></div>

      <div class="tray inset-shallow">
        &copy; {date.getFullYear()} Papaya Software House Sdn Bhd All Rights
        Reserved
      </div>

      <div class="taskbar-divider"></div>
      <div class="tray inset-shallow">
        <div class="tray-icons">
          <img
            alt=""
            class="tray-icon"
            src={task}
            title="Task Scheduler is not ready."
            onclick="showMessageBox({iconID: 'info', message: 'Good day to you!'})"
          />
        </div>
        <div
          class="taskbar-time"
          ondblclick="showMessageBox({iconID: 'info', message: 'Time to get an old clock!'})"
          title="Saturday, June 24 at 10:20 PM"
        >
          {formatAMPM()}
        </div>
      </div>
    </div>
  );
};

export default Taskbar;
