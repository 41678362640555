import "./our-services.scss";
import web from "../../../assets/icons/Web App Development.svg";
import mobile from "../../../assets/icons/Mobile App Development.svg";
import tocken from "../../../assets/icons/Token Development.svg";
import uiux from "../../../assets/icons/UIUX _ Web Design.svg";

const OurServices = () => {
  const menus = [
    {
      title: "WEB APP DEVELOPMENT",
      iconUrl: web,
      desc: "We offer a range of web app and software development services that meets the needs of your business. Leverage in our expertise to ensure a secure and reliable integration and our knowledge in emerging fintech for payment solutions.",
    },
    {
      title: "MOBILE APP DEVELOPMENT",
      iconUrl: mobile,
      desc: "Our mobile app development services include iOS and Android apps development, native and cross platform solutions and UI/UX design to transform your ideas and concept into a digital product that looks stunning and functions seamlessly.",
    },
    {
      title: "TOKEN DEVELOPMENT",
      iconUrl: tocken,
      desc: "Cryptocurrency (digital assets) and blockchain technology, also known as distributed ledger technology are seeing greater rates of adoption. We provide various token development services such as utility tokens, non-fungible tokens (NFTs) and smart contract creation.",
    },
    {
      title: "UI/UX & WEB DESIGN",
      iconUrl: uiux,
      desc: "First impression counts and your brand image is conveyed through what your customer sees and experiences using your website and digital products. An aesthetic and strategic design understands user behaviour that translates to brand loyalty in your users and customers.",
    },
  ];
  return (
    <div className="our-services-container">
      {menus.map((item) => {
        return (
          <div className="icon-with-name">
            <div class="icon-wrapper">
              <img
                class="services-img"
                draggable="false"
                src={item.iconUrl}
                alt=""
              />
            </div>
            <div class="title">{item.title}</div>
            <p class="desc">{item.desc}</p>
          </div>
        );
      })}
    </div>
  );
};

export default OurServices;
