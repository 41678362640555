import React, { useState } from "react";

const Windows98Window = (props) => {
  console.log("props", props.data);

  // const [ minimize, setMinimize ] = useState(false);

  const [resize, setResize] = useState(true);
  const [open, setOpen] = useState(true);

  const minimizeClick = () => {
    console.log("minimizeClick");
  };

  return (
    <>
      {open && (
        <div
          class="window"
          style={
            resize
              ? {
                  width: props.data.width ? props.data.width : "80%",
                  top: props.data.index,
                  left: props.data.left ? props.data.left : "10%",
                  transform: "translate(50% 50%)",
                }
              : { width: "100%", height: "100%", left: "0%", top: "0%" }
          }
        >
          <div class="title-bar">
            <div class="title-bar-text">{props.data.title}</div>
            <div class="title-bar-controls">
              <button
                aria-label="Minimize"
                onClick={() => minimizeClick()}
              ></button>
              <button
                aria-label="Maximize"
                onClick={() => setResize(!resize)}
              ></button>
              <button
                aria-label="Close"
                onClick={() => {
                  window.dispatchEvent(
                    new CustomEvent("close-window", {
                      detail: { ...props.data },
                    })
                  );
                  setOpen(false);
                }}
              ></button>
            </div>
          </div>
          <div class="window-body">
            <div class="status-bar">
              <p class="status-bar-field">{props.data.component}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Windows98Window;
