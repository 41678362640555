import "./our-project.css";
import community from "../../../assets/icons/ppy_community.svg";
import easeofuser from "../../../assets/icons/ppy_consensus.svg";
import funtionality from "../../../assets/icons/ppy_constructive.svg";

const OurProject = () => {
  const menus = [
    {
      title: "COMMUNITY",
      technology: ["ABC", "ABC", "ABC"],
      iconUrl: community,
      desc: "A chat app enables messaging and group chats with multimedia over the internet."
    },
    {
      title: "COMMUNITY",
      technology: ["ABC", "ABC", "ABC"],
      iconUrl: community,
      desc: "A chat app enables messaging and group chats with multimedia over the internet."
    },
    {
      title: "COMMUNITY",
      technology: ["ABC", "ABC", "ABC"],
      iconUrl: community,
      desc: "A chat app enables messaging and group chats with multimedia over the internet."
    }
  ];
  return (
    <div className="our-project-container">
      {menus.map((item) => {
        return (
          <div className="icon-with-name">
            <div class="icon-wrapper">
              <img
                class="services-img"
                draggable="false"
                src={item.iconUrl}
                alt=""
              />
            </div>
            <div className="bullet-list">
              <div className="bullet-item">Item 1</div>
              <div className="bullet-item">Item 2</div>
              <div className="bullet-item">Item 3</div>
            </div>
            <div class="title">{item.title}</div>
            <p class="desc">{item.desc}</p>
          </div>
        );
      })}
    </div>
  );
};

export default OurProject;
