// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallary {
  height: 600px;
  overflow: auto;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
}

.image-gallary {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;
}
.image-gallary .img-class {
  /* margin-bottom: 10px; */
  margin: 10px;
  width: 28%;
  height: 300px;
  /* padding: 20px; */
  border-radius: 12px;
}

@media (max-width: 650px) {
  .button-container {
    width: 100%;
  }
  .img-class {
    width: 95% !important;
  }
}
.event-title {
  font-family: px sans nouveaux;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 20px;
  display: block;
}

.event-desc {
  font-family: HarmonyOS Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 10px;
  display: block;
}

.img-full-width {
  width: 80%;
  height: 400px;
  /* padding: 20px; */
  border: solid 1px #ccc;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/our-gallary/our-gallary.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,UAAA;EACA,cAAA;AACF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,eAAA;EACA,sBAAA;AADF;AAGE;EACE,yBAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AADJ;;AAKA;EACE;IACE,WAAA;EAFF;EAIA;IACE,qBAAA;EAFF;AACF;AAKA;EACE,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AAHF;;AAMA;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AAHF;;AAKA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;AAFF","sourcesContent":[".gallary {\n  height: 600px;\n  overflow: auto;\n}\n\n.button-container {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  width: 50%;\n  margin: 0 auto;\n}\n\n\n\n.image-gallary {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  cursor: pointer;\n  background-color: #fff;\n  \n  .img-class {\n    /* margin-bottom: 10px; */\n    margin: 10px;\n    width: 28%;\n    height: 300px;\n    /* padding: 20px; */\n    border-radius: 12px;\n  }\n}\n\n@media (max-width: 650px){\n  .button-container {\n    width: 100%;\n  }\n  .img-class {\n    width: 95% !important;\n  }\n}\n\n.event-title {\n  font-family: px sans nouveaux;\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 28px;\n  letter-spacing: 0.04em;\n  text-align: left;\n  margin-bottom: 20px;\n  display: block;\n}\n\n.event-desc {\n  font-family: HarmonyOS Sans;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n  margin-bottom: 10px;\n  display: block;\n}\n.img-full-width {\n  width: 80%;\n  height: 400px;\n  /* padding: 20px; */\n  border: solid 1px #ccc;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
