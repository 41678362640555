import "./desktop.css";
import Taskbar from "../taskbar/taskbar";
import DesktopMenu from "../desktop-menu/desktop-menu";
import Windows98Window from "../window/window";
import { useState } from "react";

const Desktop = () => {
  const [openWindows, setOpenWindows] = useState([]);

  console.log("openWindows", openWindows);
  const onItemClick = (item) => {
    console.log("item========", item);
    setOpenWindows([...openWindows, item]);
  };

  window.addEventListener("close-window", (data) => {
    console.log("close-window", data.detail.id);
    let r = new Array(...openWindows);
    r.filter((item) => item.id !== data.detail.id);
    console.log("--", r);
    setOpenWindows(r);
  });

  return (
    <div className="App">
      {openWindows &&
        openWindows.length &&
        openWindows.map((item) => {
          console.log("item", item);
          return <Windows98Window data={item} />;
        })}
      <DesktopMenu onDoubleClick={(item) => onItemClick(item)} />
      <Taskbar data={openWindows} />
    </div>
  );
};

export default Desktop;
